@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
.main_hero__1XUhn {
  height: 100%;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  min-width: 100vw;
  background-repeat: no-repeat;
}

.main_center_box__2B344 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 401px;
  min-height: 463px;
  background-color: white;
  border-radius: 8px;
}

.main_back_button__3h6r1 {
  position: absolute;
  top: 40px;
  left: 40px;
}

@media screen and (max-width: 992px) {
  .main_center_box__2B344 {
    min-width: 301px;
    min-height: 363px;
    top: 22em;
  }

  .main_back_button__3h6r1 {
    display: none;
  }
}

.main_payment_box__1S1jx {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex: 1 1;
  border: 1px solid #e7e7e8;
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 56px;
  cursor: pointer;
}

.main_box_header__J0sFa {
  position: relative;
  background-color: #f7f7f7;
  border: 1px solid #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #dcdcdc;
  text-align: center;
  padding: 16px 0 40px;
}

.main_box_header_img__2KQIj {
  position: absolute;
  bottom: -60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_form_content__13z55 {
  padding: 0 24px;
  margin-top: 40px !important;
}

.main_form_content_head__2NLrk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:root {
  --main-color: #ff6645;
}

* {
  font-family: 'Poppins', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body,
html {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 600;
}

h2 {
  font-weight: 500;
}

h3 {
  font-weight: 400;
}

h4 {
  font-weight: 300;
}

p {
  font-weight: 300;
  color: #606084;
  font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 4px 0;
}

ul {
  padding: 0;
}

span {
  color: #606084;
  font-size: 16px;
  font-weight: 300;
}

.hr {
  height: 0.1px;
  width: 100%;
  background-color: #e7e7e8;
  margin: 10px 0;
}

.wpwl-control {
  background-color: #f7f7f7 !important;
  border: 1px solid #e7e7e8 !important;
  border-radius: 4px !important;
}

.flex {
  display: flex;
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.main-color {
  color: #ff6645;
  color: var(--main-color);
}

.wpwl-form-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.wpwl-group {
  width: 100%;
  padding-right: 0;
}

.wpwl-group-expiry,
.wpwl-group-cvv {
  /* width: 40%; */
}

.wpwl-form-card {
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 16px 0;
}

.wpwl-group-brand {
  display: none;
}

.wpwl-label-cardNumber,
.wpwl-label-expiry,
.wpwl-label-cardHolder,
.wpwl-label-cvv {
  display: none !important;
}

.wpwl-label-cardNumber {
  width: 100%;
}

.wpwl-button-pay {
  width: 100%;
  background-color: rgba(255, 102, 69, 0.8);
  border: none;
  margin-bottom: 0;
}

.wpwl-button-pay:hover {
  background-color: #ff6645;
  background-color: var(--main-color);
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.up-arrow {
  transform: rotate(180deg);
}

.normal-text {
  font-weight: 500;
}

.active {
  border: 1px solid #ff6645 !important;
  border: 1px solid var(--main-color) !important;
  background-color: #fff2ef;
}

