.hero {
  height: 100%;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  min-width: 100vw;
  background-repeat: no-repeat;
}

.center_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 401px;
  min-height: 463px;
  background-color: white;
  border-radius: 8px;
}

.back_button {
  position: absolute;
  top: 40px;
  left: 40px;
}

@media screen and (max-width: 992px) {
  .center_box {
    min-width: 301px;
    min-height: 363px;
    top: 22em;
  }

  .back_button {
    display: none;
  }
}

.payment_box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex: 1;
  border: 1px solid #e7e7e8;
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 56px;
  cursor: pointer;
}

.box_header {
  position: relative;
  background-color: #f7f7f7;
  border: 1px solid #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #dcdcdc;
  text-align: center;
  padding: 16px 0 40px;
}

.box_header_img {
  position: absolute;
  bottom: -60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form_content {
  padding: 0 24px;
  margin-top: 40px !important;
}

.form_content_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
