@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --main-color: #ff6645;
}

* {
  font-family: 'Poppins', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body,
html {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 600;
}

h2 {
  font-weight: 500;
}

h3 {
  font-weight: 400;
}

h4 {
  font-weight: 300;
}

p {
  font-weight: 300;
  color: #606084;
  font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 4px 0;
}

ul {
  padding: 0;
}

span {
  color: #606084;
  font-size: 16px;
  font-weight: 300;
}

.hr {
  height: 0.1px;
  width: 100%;
  background-color: #e7e7e8;
  margin: 10px 0;
}

.wpwl-control {
  background-color: #f7f7f7 !important;
  border: 1px solid #e7e7e8 !important;
  border-radius: 4px !important;
}

.flex {
  display: flex;
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.main-color {
  color: var(--main-color);
}

.wpwl-form-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.wpwl-group {
  width: 100%;
  padding-right: 0;
}

.wpwl-group-expiry,
.wpwl-group-cvv {
  /* width: 40%; */
}

.wpwl-form-card {
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 16px 0;
}

.wpwl-group-brand {
  display: none;
}

.wpwl-label-cardNumber,
.wpwl-label-expiry,
.wpwl-label-cardHolder,
.wpwl-label-cvv {
  display: none !important;
}

.wpwl-label-cardNumber {
  width: 100%;
}

.wpwl-button-pay {
  width: 100%;
  background-color: rgba(255, 102, 69, 0.8);
  border: none;
  margin-bottom: 0;
}

.wpwl-button-pay:hover {
  background-color: var(--main-color);
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.up-arrow {
  transform: rotate(180deg);
}

.normal-text {
  font-weight: 500;
}

.active {
  border: 1px solid var(--main-color) !important;
  background-color: #fff2ef;
}
